
import React, { useEffect } from "react";
import Navbar from "../landing/header/Navbar";
import { useTranslation } from "react-i18next";
import Faster from "../landing/Faster/Faster";
import Footer from "../landing/footer/Footer";
import './terms.scss'
import TermsDetail from "./TermsDetail";

const Terms = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="wrapper-banner">
                <div className="wrapper-structure">
                    <video className='main-banner-video'
                        muted="muted" playsinline="playsinline"
                        autoPlay
                        loop
                        width="100%"
                        id="myVideo">
                        <source src="\assets\videophone.mp4" type="video/mp4" />
                    </video>

                    <Navbar />

                    <div className="mid-side-grid">
                        <section className="faqs-section">
                            <div className="main-content">
                                <h6>Terms of Service</h6>
                                <h4>Terms of Service</h4>
                            </div>
                        </section>
                    </div>
                  
                </div>
            </div>
            <TermsDetail />
   
            <Footer />
        </>
    )
}

export default Terms
