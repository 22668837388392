import React from 'react'
import './visionmission.scss';
import { useTranslation } from 'react-i18next';

const Visionmission = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className='main_faqsnew' id="visionmission">
      <div className='custom-container'>
        <div className='outer_third'>
          <div className='inner_inte'>
            <h2>{t('Vison-t1')}</h2>
            <h5 className=''></h5>

            {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
          </div>
          <h1>{t('Vison-t2')}</h1>
          <div className='inner_faqs'>
          <div className='faqs_cards'>
            <h2>01</h2>
            <h3>{t('Vison-t3')}</h3>
            <p>{t('Vison-t4')}</p>
          </div>
          <div className='faqs_cards'>
            <h2>02</h2>
            <h3>{t('Vison-t5')}</h3>
            <p>{t('Vison-t6')}</p>
          </div>
          </div>
         

        </div>
        </div>
      </section>
    </>
  )
}

export default Visionmission