import React from 'react'
import './faster.scss';
import { useTranslation } from 'react-i18next';

const Faster = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className='main_faster'  id="faster">
                <div className='outer_third'>
                  <h1>{t('Future-t1')}</h1>
                  <p>{t('Future-t2')}</p>
                 <p>{t('Future-t3')}</p>
                 <p>{t('Future-t4')}</p>
                 <a href="\Olympus AI Whitepaper - Final - English.pdf" target='blank'>
                  <button>{t('Future-btn')}</button>
                  </a>
                </div>
            </section>
        </>
    )
}

export default Faster