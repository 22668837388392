import React from 'react'

const TermsDetail = () => {
    return (
        <div>
            <div className="wrapper-withouttopside">
                <div className="wrapper-integration">
                    <div className="wrapper-structure">
                        <div className="left-side-grid" style={{ background: "#fff" }}></div>
                        <div className="mid-side-grid">
                            <div className="termscontent">
                                <div className="termsinnercontent">
                                    <p className="datepara">Terms of Service
                                    </p>
                                    <div className='mainouterdiv_outer'>
                                        <div className='mainouterdiv_iner'>
                                            <h4 className='maintophngd'>
                                                1. Introduction and Acceptance
                                            </h4>
                                            <h6 className='seclinghthndg'>
                                                1.1 Binding Agreement

                                            </h6>
                                            <p className='commmmonpara'>
                                            These Terms of Service (“Terms”), along with our Privacy Policy and any other referenced agreements (collectively, the “Agreement”), constitute a legally binding contract between you (“User,” “you,” or “your”) and Olympus AI (“we,” “us,” or “our”) governing your access to and use of our framework for AI agent creation, tokenization, and deployment, including blockchain-enabled and token economics services (collectively, the “Platform”). By accessing, using, or registering on the Platform, you agree to be bound by these Terms, regardless of whether you have signed any explicit agreement.                                            </p>
                                            <div className='mainitemsdiv'>
                                                <h6 className='seclinghthndg'>
                                                    1.2 Eligibility and Authority

                                                </h6>
                                                <p className='ullipara'>
                                                By using the Platform, you represent that:

                                                </p>
                                                <ul className=''>


                                                    <li className=''>
                                                    You are at least 18 years old and legally capable of entering into this Agreement.
                                                    </li>
                                                    <li className=''>
                                                    You have the authority to bind any entity you represent.
                                                    </li>
                                                    <li className=''>
                                                    Your use of the Platform complies with all applicable laws.
                                                    </li>
                                                    <li className=''>
                                                    You understand and accept the inherent risks associated with AI, blockchain, and tokenized systems.
                                                    </li>
                                                    <li className=''>
                                                    You are not located in, under the control of, or a national or resident of any country subject to economic sanctions by any relevant authority.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='secsection 2'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        2. Platform Description and Risks
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        2.1 Capabilities of Olympus AI

                                                    </h6>
                                                    <p className='ullipara'>
                                                    The Platform provides a comprehensive environment for creating and deploying AI agents with capabilities such as:

                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        Cross-platform deployment.
                                                        </li>
                                                        <li className=''>
                                                        Automated token generation and trading.

                                                        </li>
                                                        <li className=''>
                                                        Advanced memory management and intelligent processing.

                                                        </li>
                                                        <li className=''>
                                                        Integration with major blockchain networks.

                                                        </li>
                                                        {/* <li className=''>
                                                            Customer support
                                                        </li>
                                                        <li className=''>
                                                            Real-world task execution through blockchain integration
                                                        </li> */}

                                                    </ul>
                                                  
                                                </div>
                                                <p className='ullipara'>
                                                    Platform features and capabilities may be modified, updated, or discontinued at Olympus AI’s sole discretion without liability.

                                                    </p>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        2.2 Acknowledgment of Risks
                                                    </h6>
                                                    <p className='ullipara'>
                                                    By using the Platform, you acknowledge that:

                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        AI agents may execute autonomous decisions.
                                                        </li>
                                                        <li className=''>
                                                        Blockchain transactions are irreversible.
                                                        </li>
                                                        <li className=''>
                                                        Technical or third-party service disruptions may affect service availability.
                                                        </li>
                                                        <li className=''>
                                                        Regulatory changes may impact the use of the Platform or tokens.
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        2.3 Disclaimers

                                                    </h6>
                                                    <p className='ullipara'>
                                                    We make no guarantees regarding:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        Platform availability or output accuracy.
                                                        </li>
                                                        <li className=''>
                                                        Economic outcomes from tokenized AI agents.
                                                        </li>
                                                        <li className=''>
                                                        Compliance of outputs with specific industry regulations.

                                                        </li>
                                                        {/* <li className=''>
                                                            The quality, accuracy, or suitability of any outputs generated by our AI employees, which may vary and are provided “as-is.”
                                                        </li>
                                                        <li className=''>
                                                            We reserve the right to modify, suspend, or discontinue any part of the Services without liability.
                                                        </li> */}


                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='secsection 3'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        3. User Obligations and Conduct
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        3.1  Compliance
                                                    </h6>
                                                    <p className='ullipara'>
                                                    You agree to:

                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        Use the Platform as intended and for lawful purposes.
                                                        </li>
                                                        <li className=''>
                                                        Protect your credentials and monitor agent actions.

                                                        </li>
                                                        <li className=''>
                                                        Promptly notify Olympus AI of unauthorized access or security breaches.
                                                        </li>
                                                        {/* <li className=''>
                                                            Use the Services solely for authorized business purposes.
                                                        </li>
                                                        <li className=''>
                                                            Review and confirm actions initiated by AI employees as necessary.
                                                        </li> */}


                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.2 Prohibited Activities
                                                    </h6>
                                                    <p className='ullipara'>
                                                    You may not:

                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        Exploit, reverse-engineer, or decompile the Platform.
                                                        </li>
                                                        <li className=''>
                                                        Circumvent token or transaction mechanisms.
                                                        </li>
                                                        <li className=''>
                                                        Use the Platform in ways that harm its ecosystem or reputation.
                                                        </li>
                                                        <li className=''>
                                                        Deploy AI agents for illegal, malicious, or unethical activities.
                                                        </li>
                                                        {/* <li className=''>
                                                            Share or transfer account access to unauthorized parties.
                                                        </li>
                                                        <li className=''>
                                                            Engage in activities harmful to Olympus AI’s reputation, Services, or users.
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='secsection 4'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        4. Intellectual Property
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        4.1 Ownership
                                                    </h6>
                                                    <p className='ullipara'>
                                                    All intellectual property, including the framework, tools, and tokens, is owned by Olympus AI. You acknowledge that your use of the Platform grants no ownership rights over its intellectual property.

                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className=''>
                                                            All intellectual property in the Services, including AI models, algorithms, and platform technology, remains the exclusive property of Olympus AI.
                                                        </li>
                                                        <li className=''>
                                                            Output generated by AI employees is licensed, not sold, to you in accordance with these Terms.
                                                        </li>
                                                        <li className=''>
                                                            You retain rights to your input data, subject to the licenses granted herein.
                                                        </li>
                                                    </ul> */}
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        4.2 License
                                                    </h6>
                                                    <p className='ullipara'>
                                                    You are granted a non-exclusive, revocable license to use the Platform for permitted purposes in accordance with these Terms.
                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className=''>
                                                            Access and use the Services as permitted by these Terms.
                                                        </li>
                                                        <li className=''>
                                                            Use AI-generated content solely for authorized business purposes.
                                                        </li>
                                                    </ul> */}
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        4.3 Restrictions

                                                    </h6>
                                                    <p className='ullipara'>
                                                    You may not:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        Create derivative works of the Platform’s proprietary tools.
                                                        </li>
                                                        <li className=''>
                                                        Sell, sublicense, or redistribute any part of the Platform.                                                        </li>

                                                        <li className=''>
                                                        Infringe on Olympus AI’s intellectual property rights in any manner.
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>


                                            <div className='secsection 5'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        5.Token Economics
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        5.1 Token Utility
                                                    </h6>
                                                    <p className='ullipara'>
                                                    The OAI token serves as the exclusive trading pair for all AI agent tokens, providing:
                                                    </p>

                                                    <ul className=''>
                                                        <li className=''>
                                                        Access to advanced features.
                                                        </li>
                                                        <li className=''>
                                                        Governance participation.                                                        </li>
                                                        <li className=''>
                                                        Staking rewards.                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        5.2 Payment Terms
                                                    </h6>
                                                    <p className='ullipara'>
                                                    All transactions, including token purchases, are final and non-refundable. Users may incur additional transaction fees for certain tokenized services. </p>
                                                    {/* <ul className=''>
                                                        <li className=''>
                                                            All payments and token purchases are final, with no refunds.
                                                        </li>
                                                        <li className=''>
                                                            You are responsible for all applicable taxes.
                                                        </li>
                                                        <li className=''>
                                                            Transactions conducted by AI employees may incur additional fees.
                                                        </li>
                                                    </ul> */}
                                                </div>

                                            </div>

                                            <div className='secsection 6'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        6. Security and Privacy

                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        6.1 Data Handling
                                                    </h6>
                                                    <p className='ullipara'>
                                                    We adhere to industry-standard security practices to protect your data, including encryption and role-based access control.</p>

                                                    {/* <ul className=''>
                                                        <li className=''>
                                                            We collect and process data per our Privacy Policy.
                                                        </li>
                                                        <li className=''>
                                                            You are responsible for ensuring your data is accurate and lawful.
                                                        </li>

                                                    </ul> */}
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.2 Compliance
                                                    </h6>
                                                    <p className='ullipara'>
                                                    You are responsible for compliance with all data privacy laws relevant to your activities, including obtaining necessary consents for data processing.
                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className=''>
                                                            We implement industry-standard security measures but cannot guarantee complete security.
                                                        </li>
                                                        <li className=''>
                                                            You agree to notify Olympus AI promptly of any security breaches.
                                                        </li>

                                                    </ul> */}
                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.3 Liability for Security
                                                    </h6>
                                                    <p className='ullipara'>
                                                    While Olympus AI employs robust security measures, we cannot guarantee complete protection against all cyber threats.                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className=''>
                                                            Olympus AI complies with relevant data protection laws. You are responsible for obtaining any necessary consents for data processing.
                                                        </li>


                                                    </ul> */}
                                                </div>

                                            </div>

                                            <div className='secsection 7'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        7. Limitation of Liability
                                                    </h4>
                                                    <p className='ullipara'>
                                                    To the fullest extent permitted by law, Olympus AI is not liable for:                                                    </p>
                                                    {/* <h6 className='seclinghthndg'>
                                                        7.1 Disclaimer of Warranties
                                                    </h6>
                                                    <p className='ullipara'>
                                                        THE SERVICES, INCLUDING ALL CONTENT, AI EMPLOYEES, AND MATERIALS, ARE PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. OLYMPUS AI DOES NOT GUARANTEE THE QUALITY, ACCURACY, OR SUITABILITY OF OUTPUTS GENERATED BY THE AI EMPLOYEES.
                                                    </p> */}
                                                      <ul className=''>
                                                        <li className=''>
                                                        Indirect, incidental, or consequential damages.
                                                        </li>
                                                        <li className=''>
                                                        Losses related to agent actions or token trading.
                                                        </li>
                                                        <li className=''>
                                                        Interruption, delay, or failure of the Platform.
                                                        </li>
                                                        {/* <li className=''>
                                                            Blockchain or third-party service disruptions.
                                                        </li> */}





                                                    </ul>

                                                </div>
                                                {/* <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        7.2 Limitation of Liability
                                                    </h6>
                                                    <p className='ullipara'>
                                                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, OLYMPUS AI SHALL NOT BE LIABLE FOR:
                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                            Indirect, incidental, or consequential damages.
                                                        </li>
                                                        <li className=''>
                                                            Lost profits, data loss, or business interruption.
                                                        </li>
                                                        <li className=''>
                                                            Actions or decisions made by AI employees.
                                                        </li>
                                                        <li className=''>
                                                            Blockchain or third-party service disruptions.
                                                        </li>





                                                    </ul>
                                                </div> */}


                                            </div>

                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        8. Indemnification
                                                    </h4>


                                                    <p className='ullipara'>
                                                    You agree to indemnify Olympus AI from claims, damages, or expenses arising from:                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        Your use of the Platform.
                                                        </li>
                                                        <li className=''>
                                                        Violations of these Terms.
                                                        </li>
                                                        <li className=''>
                                                        Any third-party claims related to your actions on the Platform.
                                                        </li>






                                                    </ul>

                                                </div>



                                            </div>

                                            <div className='secsection 9'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        9.Termination
                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        9.1 Termination Rights

                                                    </h6>
                                                    <p className='commmmonpara'>
                                                    We may suspend or terminate your access for breaches or at our discretion, with or without notice.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        9.2  Post-Termination
                                                    </h6>
                                                    <p className='ullipara'>
                                                    Upon termination:

                                                    </p>
                                                    <ul className=''>
                                                        <li className=''>
                                                        All access rights and licenses granted will terminate.

                                                        </li>
                                                        <li className=''>
                                                        Olympus AI may delete user data after termination in accordance with its data retention policies.

                                                        </li>
                                                        {/* <li className=''>
                                                            Olympus AI may delete your data post-termination.
                                                        </li> */}
                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='secsection 10'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        10. Governing Law and Dispute Resolution

                                                    </h4>
                                                    <h6 className='seclinghthndg'>
                                                        10.1  Jurisdiction
                                                    </h6>
                                                    <p className='commmmonpara'>
                                                    These Terms are governed by the laws of England and Wales.                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.2  Arbitration

                                                    </h6>
                                                    <p className='ullipara'>
                                                    Disputes shall be resolved through binding arbitration in London, UK, under the LCIA Rules.
                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        10.3 Class Action Waiver


                                                    </h6>
                                                    <p className='ullipara'>
                                                    You waive the right to bring or participate in any class action against Olympus AI.
                                                    </p>

                                                </div>

                                            </div>

                                            <div className='secsection 11'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        11. Modifications

                                                    </h4>

                                                    <p className='commmmonpara'>
                                                    We reserve the right to modify these Terms. Continued use indicates acceptance of any changes.
                                                    </p>

                                                </div>


                                            </div>
                                            <div className='secsection 12'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        12. Contact Information

                                                    </h4>
                                                    <p className='commmmonpara'>
                                                    For inquiries or legal concerns, contact:                                                    </p>
                                                    <h6 className='seclinghthndg'>
                                                        12.1 Force Majeure
                                                    </h6>
                                                    <p className='commmmonpara'>
                                                        Olympus AI shall not be liable for delays or failures caused by circumstances beyond its control.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.2 Assignment


                                                    </h6>
                                                    <p className='ullipara'>
                                                        Olympus AI may assign its rights and obligations under these Terms; you may not assign your rights without our consent.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.3 Severability

                                                    </h6>
                                                    <p className='ullipara'>
                                                        If any provision is found invalid, the remaining provisions shall remain in effect.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.4 Entire Agreement

                                                    </h6>
                                                    <p className='ullipara'>
                                                        These Terms constitute the entire agreement between you and Olympus AI.
                                                    </p>

                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        12.5 No Waiver

                                                    </h6>
                                                    <p className='ullipara'>
                                                        No delay in exercising any right shall constitute a waiver of that right.
                                                    </p>

                                                </div>


                                            </div>
                                            <div className='secsection 13'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        13. Contact Information
                                                    </h4>

                                                    <p className='commmmonpara'>
                                                        For questions, contact:
                                                    </p>

                                                    <p className='commmmonpara'>
                                                        Legal Department
                                                    </p>

                                                    <p className='commmmonpara'>
                                                        support@olympusai.io
                                                    </p>

                                                    <p className='commmmonpara'>
                                                    By using the Platform, you confirm that you have read, understood, and agree to these Terms.
                                                    </p>




                                                </div>




                                            </div>



                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className="right-side-grid" style={{ background: "#fff" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsDetail
