import React from 'react'
import './features.scss';
import { useTranslation } from 'react-i18next';

const Features = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='main_feat' id="features">
                <div className='custom-container'>
                    <div className='outer_feat'>
                        <div className='inner_inte'>
                            <h2>{t('Feature-t1')}</h2>
                            <h5 className=''></h5>

                            {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
                        </div>
                        <h1>{t('Feature-t2')}</h1>
                        <div className='inner_feat'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7307 10.7306C13.775 7.6863 18.4483 6.39856 24.9051 6.39856H40.2616C46.7184 6.39856 51.3918 7.6863 54.4361 10.7306C57.4804 13.7749 58.7681 18.4483 58.7681 24.9051V40.2615C58.7681 46.7183 57.4804 51.3917 54.4361 54.436C51.3918 57.4803 46.7184 58.768 40.2616 58.768H24.9051C18.4483 58.768 13.775 57.4803 10.7307 54.436C7.68636 51.3917 6.39862 46.7183 6.39862 40.2615V24.9051C6.39862 18.4483 7.68636 13.7749 10.7307 10.7306ZM11.5659 11.5659C8.85156 14.2803 7.57989 18.5648 7.57989 24.9051V40.2615C7.57989 46.6018 8.85156 50.8864 11.5659 53.6007C14.2803 56.3151 18.5649 57.5868 24.9051 57.5868H40.2616C46.6019 57.5868 50.8864 56.3151 53.6008 53.6007C56.3152 50.8864 57.5868 46.6018 57.5868 40.2615V24.9051C57.5868 18.5648 56.3152 14.2803 53.6008 11.5659C50.8864 8.8515 46.6019 7.57983 40.2616 7.57983H24.9051C18.5649 7.57983 14.2803 8.8515 11.5659 11.5659Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7689 18.7687C20.7334 16.8042 23.7272 15.9963 27.7845 15.9963H37.3823C41.4396 15.9963 44.4334 16.8042 46.398 18.7687C48.3625 20.7333 49.1704 23.7271 49.1704 27.7844V37.3822C49.1704 41.4395 48.3625 44.4333 46.398 46.3979C44.4334 48.3624 41.4396 49.1702 37.3823 49.1702H27.7845C23.7272 49.1702 20.7334 48.3624 18.7689 46.3979C16.8043 44.4333 15.9965 41.4395 15.9965 37.3822V27.7844C15.9965 23.7271 16.8043 20.7333 18.7689 18.7687ZM19.6041 19.604C17.9695 21.2386 17.1777 23.8436 17.1777 27.7844V37.3822C17.1777 41.323 17.9695 43.9279 19.6041 45.5626C21.2388 47.1972 23.8437 47.989 27.7845 47.989H37.3823C41.3231 47.989 43.9281 47.1972 45.5627 45.5626C47.1973 43.9279 47.9891 41.323 47.9891 37.3822V27.7844C47.9891 23.8436 47.1973 21.2386 45.5627 19.604C43.9281 17.9694 41.3231 17.1776 37.3823 17.1776H27.7845C23.8437 17.1776 21.2388 17.9694 19.6041 19.604Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8182 0C20.1444 0 20.4089 0.264436 20.4089 0.590633V6.98916C20.4089 7.31536 20.1444 7.57979 19.8182 7.57979C19.492 7.57979 19.2276 7.31536 19.2276 6.98916V0.590633C19.2276 0.264436 19.492 0 19.8182 0Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.5834 0C32.9096 0 33.174 0.264436 33.174 0.590633V6.98916C33.174 7.31536 32.9096 7.57979 32.5834 7.57979C32.2572 7.57979 31.9927 7.31536 31.9927 6.98916V0.590633C31.9927 0.264436 32.2572 0 32.5834 0Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.3805 0C45.7067 0 45.9711 0.264436 45.9711 0.590633V6.98916C45.9711 7.31536 45.7067 7.57979 45.3805 7.57979C45.0543 7.57979 44.7899 7.31536 44.7899 6.98916V0.590633C44.7899 0.264436 45.0543 0 45.3805 0Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M57.5868 19.7862C57.5868 19.46 57.8512 19.1956 58.1774 19.1956H64.576C64.9022 19.1956 65.1666 19.46 65.1666 19.7862C65.1666 20.1124 64.9022 20.3768 64.576 20.3768H58.1774C57.8512 20.3768 57.5868 20.1124 57.5868 19.7862Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M57.5868 32.5834C57.5868 32.2572 57.8512 31.9928 58.1774 31.9928H64.576C64.9022 31.9928 65.1666 32.2572 65.1666 32.5834C65.1666 32.9096 64.9022 33.1741 64.576 33.1741H58.1774C57.8512 33.1741 57.5868 32.9096 57.5868 32.5834Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M57.5868 45.3803C57.5868 45.0541 57.8512 44.7897 58.1774 44.7897H64.576C64.9022 44.7897 65.1666 45.0541 65.1666 45.3803C65.1666 45.7065 64.9022 45.9709 64.576 45.9709H58.1774C57.8512 45.9709 57.5868 45.7065 57.5868 45.3803Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.3805 57.5867C45.7067 57.5867 45.9711 57.8511 45.9711 58.1773V64.5758C45.9711 64.902 45.7067 65.1665 45.3805 65.1665C45.0543 65.1665 44.7899 64.902 44.7899 64.5758V58.1773C44.7899 57.8511 45.0543 57.5867 45.3805 57.5867Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.6153 57.5867C32.9415 57.5867 33.2059 57.8511 33.2059 58.1773V64.5758C33.2059 64.902 32.9415 65.1665 32.6153 65.1665C32.2891 65.1665 32.0247 64.902 32.0247 64.5758V58.1773C32.0247 57.8511 32.2891 57.5867 32.6153 57.5867Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8182 57.5867C20.1444 57.5867 20.4089 57.8511 20.4089 58.1773V64.5758C20.4089 64.902 20.1444 65.1665 19.8182 65.1665C19.492 65.1665 19.2276 64.902 19.2276 64.5758V58.1773C19.2276 57.8511 19.492 57.5867 19.8182 57.5867Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.10352e-05 19.7862C6.10352e-05 19.46 0.264497 19.1956 0.590694 19.1956H6.98922C7.31542 19.1956 7.57986 19.46 7.57986 19.7862C7.57986 20.1124 7.31542 20.3768 6.98922 20.3768H0.590694C0.264497 20.3768 6.10352e-05 20.1124 6.10352e-05 19.7862Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.10352e-05 32.5834C6.10352e-05 32.2572 0.264497 31.9928 0.590694 31.9928H6.98922C7.31542 31.9928 7.57986 32.2572 7.57986 32.5834C7.57986 32.9096 7.31542 33.1741 6.98922 33.1741H0.590694C0.264497 33.1741 6.10352e-05 32.9096 6.10352e-05 32.5834Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.10352e-05 45.3803C6.10352e-05 45.0541 0.264497 44.7897 0.590694 44.7897H6.98922C7.31542 44.7897 7.57986 45.0541 7.57986 45.3803C7.57986 45.7065 7.31542 45.9709 6.98922 45.9709H0.590694C0.264497 45.9709 6.10352e-05 45.7065 6.10352e-05 45.3803Z" fill="#010102" />
                                </svg>
                                <h2>{t('Feature-t3')}</h2>
                                <p>{t('Feature-p1')}</p>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7854 34.0381C13.1042 34.0381 13.3625 34.2965 13.3625 34.6152C13.3625 43.9392 22.0779 50.762 33.1042 50.762C44.1304 50.762 52.8458 43.9392 52.8458 34.6152C52.8458 34.2965 53.1042 34.0381 53.4229 34.0381C53.7416 34.0381 54 34.2965 54 34.6152V46.1812C54 56.3262 44.5665 64.42 33.1042 64.42C21.6418 64.42 12.2083 56.3262 12.2083 46.1812V34.6152C12.2083 34.2965 12.4667 34.0381 12.7854 34.0381ZM13.3625 40.5151V46.1812C13.3625 55.5422 22.1221 63.2658 33.1042 63.2658C44.0862 63.2658 52.8458 55.5422 52.8458 46.1812V40.5151C49.9881 47.4422 42.1559 51.9162 33.1042 51.9162C24.0525 51.9162 16.2202 47.4422 13.3625 40.5151Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1042 1.90078C27.612 1.90078 22.684 3.82281 19.1042 6.94754L19.104 6.94778C15.555 10.0417 13.3625 14.2973 13.3625 18.9854C13.3625 21.7219 14.1137 24.2438 15.4348 26.4055L15.4355 26.4067C18.654 31.7007 25.3205 35.1321 33.1042 35.1321C40.8878 35.1321 47.5543 31.7007 50.7729 26.4067L50.7735 26.4055C52.0946 24.2438 52.8458 21.7219 52.8458 18.9854C52.8458 14.2985 50.6543 10.0426 47.1043 6.91645C43.5238 3.82227 38.5955 1.90078 33.1042 1.90078ZM18.3455 6.07777C22.143 2.76316 27.343 0.746582 33.1042 0.746582C38.8653 0.746582 44.0644 2.76312 47.861 6.04492L47.8649 6.04834L47.8649 6.04836C51.6284 9.36154 54 13.9201 54 18.9854C54 21.9379 53.1883 24.6673 51.7588 27.0068C48.2876 32.7158 41.2001 36.2863 33.1042 36.2863C25.0082 36.2863 17.9206 32.7158 14.4495 27.0068C13.02 24.6673 12.2083 21.9378 12.2083 18.9854C12.2083 13.9205 14.5796 9.36091 18.3454 6.07789" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1042 1.90078C22.1221 1.90078 13.3625 9.62434 13.3625 18.9854V34.6152C13.3625 43.9391 22.0779 50.7619 33.1042 50.7619C44.1304 50.7619 52.8458 43.9391 52.8458 34.6152V18.9854C52.8458 14.2985 50.6543 10.0426 47.1043 6.91645C43.5238 3.82227 38.5955 1.90078 33.1042 1.90078ZM12.2083 18.9854C12.2083 8.84039 21.6418 0.746582 33.1042 0.746582C38.8653 0.746582 44.0644 2.76312 47.861 6.04492L47.8649 6.04834L47.8649 6.04836C51.6284 9.36154 54 13.9201 54 18.9854V34.6152C54 44.7971 44.5223 51.9161 33.1042 51.9161C21.686 51.9161 12.2083 44.7971 12.2083 34.6152V18.9854Z" fill="#010102" />
                                </svg>
                                <h2>{t('Feature-t4')}</h2>
                                <p>{t('Feature-p2')}</p>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49814 28.0582C6.80326 28.0582 7.05061 28.3056 7.05061 28.6107V42.8776C7.05061 49.9623 8.40124 54.782 11.3017 57.8408C14.1951 60.8923 18.7468 62.3116 25.4495 62.3116H41.6892C48.3919 62.3116 52.9436 60.8923 55.837 57.8408C58.7375 54.782 60.0881 49.9623 60.0881 42.8776L60.0881 28.6107C60.0881 28.3056 60.3354 28.0582 60.6406 28.0582C60.9457 28.0582 61.193 28.3056 61.193 28.6107L61.1931 42.8776C61.1931 50.06 59.832 55.2335 56.6388 58.6011C53.4386 61.9761 48.5146 63.4166 41.6892 63.4166H25.4495C18.6241 63.4166 13.7001 61.9761 10.4999 58.6011C7.30667 55.2335 5.94566 50.06 5.94566 42.8776V28.6107C5.94566 28.3056 6.19301 28.0582 6.49814 28.0582Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9512 2.49734C25.9795 2.21498 26.2171 2 26.5009 2H40.4762C40.7603 2 40.998 2.21544 41.026 2.49815L43.0011 22.4885C43.5716 28.2886 39.2777 33.0306 33.4736 33.0306C27.6694 33.0306 23.3756 28.2888 23.9461 22.4887L25.9512 2.49734ZM27.0007 3.10495L25.0457 22.5969C24.5389 27.7496 28.3249 31.9257 33.4736 31.9257C38.6221 31.9257 42.4082 27.7498 41.9015 22.5971C41.9015 22.5971 41.9015 22.5972 41.9015 22.5971L39.9756 3.10495H27.0007Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9469 2.18173C40.0517 2.06602 40.2004 2 40.3565 2H49.4839C53.4607 2 56.3376 2.75652 58.3501 4.61551C60.3554 6.46777 61.3972 9.31905 61.9415 13.2499C61.9424 13.2565 61.9432 13.2631 61.9439 13.2698L62.7819 21.5006C63.4127 27.8397 58.7296 33.0306 52.3567 33.0306C47.1359 33.0306 42.4395 28.8065 41.9018 23.587L41.9016 23.5853L39.8068 2.60737C39.7913 2.45207 39.8422 2.29745 39.9469 2.18173ZM40.9669 3.10495L43.0009 23.4737C43.0009 23.4735 43.0009 23.474 43.0009 23.4737C43.481 28.1293 47.7023 31.9257 52.3567 31.9257C58.0736 31.9257 62.2483 27.3014 61.6825 21.6108C61.6825 21.6105 61.6825 21.611 61.6825 21.6108L60.8456 13.3916C60.3123 9.54738 59.3194 7.01501 57.6004 5.42718C55.8872 3.84471 53.3475 3.10495 49.4839 3.10495H40.9669Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.41752 4.61551C10.4301 2.75652 13.3069 2 17.2838 2H26.4111C26.5672 2 26.716 2.06603 26.8208 2.18176C26.9255 2.29749 26.9764 2.45213 26.9609 2.60745L25.5245 16.9715L24.8662 23.5837C24.8662 23.5838 24.8662 23.5836 24.8662 23.5837C24.3596 28.8065 19.6609 33.0306 14.4409 33.0306C8.06814 33.0306 3.38506 27.8399 4.01559 21.501C4.01558 21.5011 4.0156 21.5008 4.01559 21.501L4.82355 13.2717C4.82427 13.2645 4.82512 13.2572 4.82613 13.2499C5.37041 9.31905 6.41224 6.46777 8.41752 4.61551ZM5.92213 13.3907L5.11522 21.6093C4.54895 27.3003 8.72373 31.9257 14.4409 31.9257C19.0962 31.9257 23.3153 28.1296 23.7664 23.477L24.4249 16.8621L25.8006 3.10495H17.2838C13.4201 3.10495 10.8804 3.84471 9.16726 5.42718C7.4484 7.01487 6.45554 9.54695 5.92213 13.3907Z" fill="#010102" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4681 48.9169C28.8544 47.5306 30.8905 46.8884 33.4736 46.8884C36.0567 46.8884 38.0928 47.5306 39.479 48.9169C40.8653 50.3031 41.5075 52.3392 41.5075 54.9223V62.4037C41.5075 62.7089 41.2601 62.9562 40.955 62.9562H25.9922C25.687 62.9562 25.4397 62.7089 25.4397 62.4037V54.9223C25.4397 52.3392 26.0819 50.3031 27.4681 48.9169ZM28.2494 49.6982C27.1444 50.8033 26.5446 52.5078 26.5446 54.9223V61.8513H40.4025V54.9223C40.4025 52.5078 39.8028 50.8033 38.6977 49.6982C37.5926 48.5931 35.8881 47.9934 33.4736 47.9934C31.0591 47.9934 29.3545 48.5931 28.2494 49.6982Z" fill="#010102" />
                                </svg>
                                <h2>{t('Feature-t5')}</h2>
                                <p>{t('Feature-p3')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features