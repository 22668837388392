import React from 'react'

const PrivacyDetail = () => {
    return (
        <div>
            <div className="wrapper-withouttopside">
                <div className="wrapper-integration">
                    <div className="wrapper-structure">
                        <div className="left-side-grid" style={{ background: "#fff" }}></div>
                        <div className="mid-side-grid">
                            <div className="privacycontent">
                                <div className="privacyinnercontent">
                                    {/* <p className="datepara">Last Updated: November 5, 2024</p> */}

                                    <div className='mainouterdiv_outer'>
                                        <div className='mainouterdiv_iner'>

                                            <p className='commmmonpara '>
                                                Olympus AI values your privacy and is committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our services, including our website, platform, and AI-driven applications (collectively, the “Services”). By using the Services, you acknowledge and agree to the practices described in this Privacy Policy.                                            </p>

                                            <div className='secsection 2'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        1.Data Controller Information

                                                    </h4>

                                                    <p className='ullipara'>
                                                        Olympus AI acts as the data controller for the processing of your personal data under applicable data protection laws, including the EU General Data Protection Regulation (“GDPR”) and the California Consumer Privacy Act (“CCPA”).
                                                    </p>
                                                    <p className='ullipara'>
                                                        For privacy-related inquiries, please contact us at:
                                                    </p>
                                                    <p className='ullipara'>
                                                        Email: privacy@olympusai.io
                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className='ulliparass'>
                                                            Email: privacy@olympusai.io
                                                        </li>


                                                    </ul> */}
                                                </div>

                                            </div>
                                            <div className='secsection 3'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        2. Scope of the Privacy Policy
                                                    </h4>

                                                    <p className='ullipara'>
                                                        This Privacy Policy applies to:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Personal data collected and processed when you access or use the Services.                                                        </li>
                                                        <li className='ulliparass'>
                                                            All users, including customers, prospective customers, visitors, and partners.
                                                            This policy does not apply to:


                                                        </li>
                                                        <li className='ulliparass'>
                                                            Third-party services, applications, or websites that may be linked to or integrated with our Services.                                                        </li>
                                                        <li className='ulliparass'>
                                                            Anonymized or aggregated data that cannot reasonably be used to identify an individual.
                                                        </li>



                                                    </ul>
                                                </div>

                                            </div>
                                            <div className='secsection 4'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        3 Data Collection Practices

                                                    </h4>
                                                    <p className='commmmonpara p-0'>
                                                        We collect personal data through direct interactions, automated technologies, and third-party sources. The types of personal data we collect include:


                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.1 Data You Provide Directly
                                                    </h6>
                                                    <p className='ullipara'>
                                                        You may provide personal data to us when you:

                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Register an account, including name, email address, username, password, and contact details.                                                        </li>
                                                        <li className='ulliparass'>
                                                            Complete transactions, including billing address and payment details (processed by a third-party payment provider).                                                        </li>


                                                        <li>
                                                            Contact us, including feedback, customer support inquiries, and correspondence.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.2 Data Collected Automatically
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may collect certain information automatically when you interact with our Services, such as:
                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Usage Data: IP address, browser type, operating system, device information, pages viewed, and interaction timestamps.                                                        </li>
                                                        <li className='ulliparass'>
                                                            Device Data: Technical data about your device, such as hardware model, operating system, and network details.
                                                        </li>



                                                    </ul>
                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        3.3 Data from Third-Party Sources
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may obtain personal data from third parties, including:                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Social Media and Authentication Services: Data obtained if you link your accounts to our Services.                                                        </li>
                                                        <li className='ulliparass'>
                                                            Analytics Providers: Usage and performance metrics to improve user experience and optimize functionality.                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>


                                            <div className='secsection 5'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        4. Purposes of Data Processing
                                                    </h4>
                                                    <p className='ullipara'>
                                                        We process personal data for the following purposes:                                                    </p>

                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            To provide and personalize our Services, including AI-powered outputs and user experiences.                                                        </li>
                                                        <li className='ulliparass'>
                                                            To manage your account, verify identity, and maintain user relationships.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            To process payments, subscriptions, and transactions.                                                        </li>

                                                        <li className='ulliparass'>
                                                            To analyze and improve the performance, functionality, and security of the Services.                                                        </li>
                                                        <li className='ulliparass'>
                                                            To detect and prevent fraudulent activity, abuse, and unauthorized access.                                                        </li>
                                                        <li className='ulliparass'>
                                                            To communicate with you, including service updates, notifications, and marketing messages, subject to your preferences.                                                        </li>
                                                        <li className='ulliparass'>
                                                            To comply with legal and regulatory requirements, including responding to lawful requests from authorities.                                                        </li>
                                                    </ul>
                                                </div>


                                            </div>

                                            <div className='secsection 6'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        5. Legal Bases for Data Processing
                                                    </h4>
                                                    <p className='ullipara'>
                                                        We process personal data based on the following legal grounds:
                                                    </p>

                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            <span className='span_neww'>Performance of a Contract: </span>
                                                            To fulfill our obligations under our agreement with you.

                                                        </li>
                                                        <li className='ulliparass'>
                                                            <span className='span_neww'>Legitimate Interests: </span>
                                                            To enhance our Services, ensure security, and manage our business, provided your rights are not overridden.

                                                        </li>
                                                        <li className='ulliparass'>
                                                            <span className='span_neww'>Consent: </span>
                                                            For specific purposes such as sending marketing communications, where required. You may withdraw your consent at any time.


                                                        </li>
                                                        <li className='ulliparass'>
                                                            <span className='span_neww'>Legal Obligation: </span>
                                                            To comply with applicable laws and regulations.


                                                        </li>
                                                    </ul>
                                                </div>



                                            </div>

                                            <div className='secsection 7'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        6.Data Sharing and Disclosure
                                                    </h4>

                                                    <p className='commmmonpara p-0'>
                                                        We do not sell personal data. However, we may share your data as follows:                                                    </p>

                                                </div>
                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.1 Service Providers
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We engage trusted third-party providers for services such as payment processing, analytics, hosting, and customer support. These providers are bound by confidentiality and data protection obligations.                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className='ulliparass'>
                                                            Payment processing, data storage, customer support, analytics, and fraud prevention.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            These providers have limited access to your data solely for providing services on our behalf and are bound by data protection obligations.
                                                        </li>






                                                    </ul> */}
                                                </div>

                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.2 Business Transfers
                                                    </h6>
                                                    <p className='ullipara'>
                                                        In the event of a merger, acquisition, or sale of assets, personal data may be transferred to the acquiring entity, subject to the terms of this Privacy Policy.                                                    </p>

                                                </div>


                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.3  Legal Obligations and Safety

                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may disclose personal data if required by law or to protect the rights, property, or safety of Olympus AI, its users, or others.                                                    </p>
                                                    {/* <ul className=''>
                                                        <li className='ulliparass'>
                                                            Compliance with legal obligations, protection of rights, or to protect Olympus AI’s security and the safety of others.
                                                        </li>







                                                    </ul> */}
                                                </div>


                                                <div className='mainitemsdiv '>
                                                    <h6 className='seclinghthndg'>
                                                        6.4 Affiliates
                                                    </h6>
                                                    <p className='ullipara'>
                                                        We may share personal data with our affiliates for business purposes, provided such sharing complies with applicable laws.                                                    </p>

                                                </div>


                                            </div>

                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        7. International Data Transfers
                                                    </h4>


                                                    <p className='ullipara'>
                                                        We may transfer personal data to countries outside your jurisdiction, including countries that may not have equivalent data protection laws. When such transfers occur:                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            We rely on legally recognized transfer mechanisms, such as Standard Contractual Clauses, to ensure adequate protection.                                                        </li>
                                                        {/* <li className='ulliparass'>
                                                            We use Standard Contractual Clauses or rely on other legally recognized transfer mechanisms where required.
                                                        </li> */}







                                                    </ul>

                                                </div>



                                            </div>



                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        8. Data Retention
                                                    </h4>


                                                    <p className='ullipara'>
                                                        We retain personal data only as long as necessary to fulfill the purposes outlined in this Privacy Policy or to comply with legal obligations. Retention periods are based on:                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            The nature of the data and the relationship with the user.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            Legal, regulatory, and business requirements.                                                        </li>

                                                        {/* <li className='ulliparass'>
                                                            Legitimate business purposes, such as improving security, fraud detection, or resolving disputes.
                                                        </li> */}







                                                    </ul>

                                                </div>



                                            </div>

                                            <div className='secsection 9'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        9. Data Security
                                                    </h4>

                                                    <p className='commmmonpara pb-2'>
                                                        We implement robust security measures to protect personal data, including encryption, secure networks, and regular audits. While we strive to protect your data, no system is completely secure. Users are responsible for safeguarding their credentials and promptly notifying us of any unauthorized access.                                                    </p>
                                                    {/* <ul className='pb-2'>
                                                        <li className='ulliparass'>
                                                            Data encryption, access controls, secure network architecture, and regular security audits.
                                                        </li>

                                                    </ul>
                                                    <p className='commmmonpara p-0'>
                                                        Despite our efforts, no security system is completely secure. You are responsible for maintaining the confidentiality of your account credentials and promptly notifying us of any security incidents.
                                                    </p> */}

                                                </div>


                                            </div>

                                            <div className='secsection 7'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        10. Your Rights and Choices
                                                    </h4>

                                                    <p className='commmmonpara p-0'>
                                                        Depending on your location, you may have the following rights:                                                    </p>

                                                </div>
                                                <ul className=''>
                                                    <li className='ulliparass'>
                                                        <span className='span_neww'>Access and Correction:  </span>
                                                        Request access to your data and correct inaccuracies.

                                                    </li>
                                                    <li className='ulliparass'>
                                                        <span className='span_neww'>Deletion and Restriction:  </span>
                                                        Request deletion or restriction of data under certain conditions.
                                                    </li>
                                                    <li className='ulliparass'>
                                                        <span className='span_neww'>Data Portability: </span>
                                                        Obtain a copy of your data in a structured, machine-readable format.

                                                    </li>
                                                    <li className='ulliparass'>
                                                        <span className='span_neww'>Objection: </span>
                                                        Object to data processing based on legitimate interests.



                                                    </li>

                                                    <li className='ulliparass'>
                                                        <span className='span_neww'>Withdraw Consent: </span>
                                                        Revoke consent for specific processing activities.



                                                    </li>
                                                    <li className='ulliparass'>
                                                        <span className='span_neww'>Non-Discrimination: </span>
                                                        Olympus AI will not discriminate against users exercising their rights under applicable laws.



                                                    </li>


                                                </ul>
                                            </div>

                                            <p className='ullipara new_ticket'>
                                                To exercise your rights, contact us at privacy@olympusai.io. Verification may be required to process requests.                                                    </p>


                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        11. Cookies and Tracking Technologies

                                                    </h4>


                                                    <p className='ullipara'>
                                                        We use cookies and similar technologies for functionality, performance, and analytics. Types of cookies include:                                                    </p>
                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                            Essential Cookies: Required for core functionality.

                                                        </li>
                                                        <li className='ulliparass'>
                                                            Performance Cookies: Collect data to improve the Services.                                                        </li>

                                                        <li className='ulliparass'>
                                                            Advertising Cookies: Deliver relevant advertisements.
                                                            You can manage cookie preferences through your browser. Disabling cookies may impact functionality.
                                                        </li>







                                                    </ul>

                                                    {/* <p className='ullipara'>
                                                        You may manage your cookie preferences through your browser settings. However, disabling certain cookies may affect your experience.
                                                    </p> */}

                                                </div>



                                            </div>




                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        12. Children’s Privacy
                                                    </h4>


                                                    <p className='ullipara'>
                                                    Our Services are not intended for individuals under 18. We do not knowingly collect data from children. If we learn of such data collection, we will promptly delete it.                                                    </p>


                                                </div>









                                            </div>
                                            <div className='secsection 8'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        13. Policy Updates
                                                    </h4>


                                                    <p className='ullipara'>
                                                    We may revise this Privacy Policy to reflect changes in our practices or legal requirements. Updates will be indicated by the “Last Updated” date. Significant changes will be communicated to users as required by law.                                                    </p>

                                                    {/* <ul className=''>
                                                        <li className='ulliparass'>
                                                            We will notify you by updating the “Last Updated” date at the top of this policy.
                                                        </li>
                                                        <li className='ulliparass'>
                                                            In cases of significant changes, we may provide additional notice or seek your consent where required by law.
                                                        </li>







                                                    </ul> */}



                                                    {/* <p className='ullipara'>
                                                        Your continued use of the Services after any changes to this Privacy Policy constitutes acceptance of the updated terms.
                                                    </p> */}


                                                </div>









                                            </div>

                                            <div className='secsection 13'>
                                                <div className='mainitemsdiv'>
                                                    <h4 className='maintophngd'>
                                                        14. Contact Us
                                                    </h4>
                                                    <p className='ullipara'>
                                                    For questions or concerns about this Privacy Policy, please contact us at:
                                                    </p>


                                                    <ul className=''>
                                                        <li className='ulliparass'>
                                                        Email: privacy@olympusai.io
                                                        </li>








                                                    </ul>
                                                    <p className='ullipara'>
                                                    By using our Services, you confirm that you have read, understood, and agreed to this Privacy Policy.
                                                    </p>



                                                </div>




                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-grid" style={{ background: "#fff" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyDetail
