import React from 'react'
import './howitworks.scss';
import { useTranslation } from 'react-i18next';
const Howitworks = () => {
      const { t } = useTranslation();
    
    return (
        <section className='main_feat1' id="howitworks">
            <div className='custom-container'>
            <div className='outer_feat'>
                <div className='inner_inte'>
                    <h2> {t('Usecase-t1')}</h2>
                    <h5 className=''></h5>

                    {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
                </div>
                <div className='inner_works_data'>
                    <div className='top_data'>
                        <h1>{t('Usecase-t1')}</h1>
                        <p>{t('Usecase-p1')}</p>
                    </div>
                    <div className='bottom_data'>
                        <div className='inner__cards'>
                            <h2>{t('Usecase-t2')}</h2>
                            <p>{t('Usecase-p2')}</p>
                        </div>
                        <div className='inner__cards'>
                            <h2>{t('Usecase-t3')}</h2>
                            <p>{t('Usecase-p3')}</p>
                        </div>
                        <div className='inner__cards'>
                            <h2>{t('Usecase-t4')}</h2>
                            <p>{t('Usecase-p4')}</p>
                        </div>
                    </div>

                </div>
            </div>
            </div>
       
        </section>
    )
}

export default Howitworks