import React from 'react'
import './economicmodel.scss';
import { useTranslation } from 'react-i18next';

const Economicmodel = () => {
      const { t } = useTranslation();
    return (
        <>
            <section className='main_omp' id="economicmodel">
            <div className='custom-container'>
                <div className='outer_third'>
                    <div className='inner_inte'>
                        <h2>{t('Omp-t1')}</h2>
                        <h5 className=''></h5>
                        {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
                    </div>
                    <div className='third_flex'>
                        <div>
                            <h1>{t('Omp-t2')}</h1>
                            <h1>{t('Omp-t3')}</h1>
                            <p className='ptaggour'>{t('Omp-p1')}</p>
                            <p>{t('Omp-p2')}</p>
                            <p>{t('Omp-p3')}</p>
                        </div>
                        <div>
                            <img src='\assets\twocirclee.png' className='img-fluid' />
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Economicmodel