import React from 'react'
import './about.scss';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
  
  return (
    <>
      <section className='integration_main ' id="about">
        <img src='\assets\Gradient.png' className='gradient' />
        <div className='custom-container'>
          <div className='outer_integration'>
            <div className='inner_inte'>
              <h2> {t('Inti-t1')}</h2>
              {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
              <h5 className=''></h5>
            </div>
            <div className='inner_flex'>
              <div>
                <h1>{t('Inti-t2')}</h1>
                <p>{t('Inti-p1')}</p>
                <p className='inner_patg'>{t('Inti-p2')}</p>
                <p className='inner_patg'>{t('Inti-p3')}</p>
                {/* <div className='intro'>
             <h3>Introduction:</h3>
             <p className='another_p'>Revolutionizing AI Agent Creation Transform how you build and deploy AI agents with Olympus AI's comprehensive development framework. Our platform seamlessly integrates cutting-edge memory management, cross-platform deployment, and automated tokenization, empowering both technical and non-technical users to create powerful AI solutions.</p>
             </div> */}


              </div>
              <div className='five_sideimg'>
                <img className='imgdivvv img-fluid' src='\assets\fivve.svg' />
                <img src='\assets\Gradient2.png' className='gradient2 d-none' />

                <img className='imgdivvv1 img-fluid d-none' src='\assets\fivephone.svg' />
              </div>
            </div>

          </div>
        </div>

      </section>
    </>
  )
}

export default About