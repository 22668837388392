import React, { useState } from 'react'
import './satoshigpt.scss';
import { useTranslation } from 'react-i18next';

const Satoshigpt = () => {
      const { t } = useTranslation();
      var languageselected = localStorage.getItem("codelanguage");

   

    
    return (
        <>
            <section className='main_third' id="satoshigpt">
                <img src='\assets\filter.png' className='fillter'/>
                <div className='custom-container'>
                <div className='outer_third'>
                    <div className='inner_inte'>
                        <h2>{t('Third-t1')}</h2>
                        <h5 className=''></h5>

                        {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
                    </div>
                    <div className='third_flex'>
                        <div>
                            {
                                languageselected === "en" ?  <img src='\assets\screenshot.svg' className='img-fluid' /> : <img src='\assets\Satoshijpg.svg' className='img-fluid' />
                            }
                           
                            

                        </div>
                        <div>
                            <h1>{t('Third-t2')}</h1>
                            <h3>{t('Third-t3')}</h3>
                            <p>{t('Third-t4')}</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Satoshigpt