import React from 'react'
import './features.scss';
import { useTranslation } from 'react-i18next';

const Features = () => {
      const { t } = useTranslation();
    
    return (
        <>
            <section className='main_feat' id="features">
            <div className='custom-container'>
                <div className='outer_feat'>
                    <div className='inner_inte'>
                        <h2>{t('Feature-t1')}</h2>
                        <h5 className=''></h5>

                        {/* <img src='\assets\Line.svg' className='imgline img-fluid' /> */}
                    </div>
                    <h1>{t('Feature-t2')}</h1>
                    <div className='inner_feat'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
                                <circle cx="32.5" cy="32.5" r="32.13" stroke="#010102" stroke-width="0.739979" />
                                <circle cx="32.4997" cy="36.9515" r="27.678" stroke="#010102" stroke-width="0.739979" />
                                <circle cx="32.5001" cy="41.4038" r="23.2259" stroke="#010102" stroke-width="0.739979" />
                            </svg>
                            <h2>{t('Feature-t3')}</h2>
                            <p>{t('Feature-p1')}</p>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65" fill="none">
                                <circle cx="32.8392" cy="32.5" r="32.13" stroke="#010102" stroke-width="0.739979" />
                                <circle cx="32.8392" cy="32.5" r="15.2122" stroke="#010102" stroke-width="0.739979" />
                                <path d="M5.33425 48.38L32.8392 0.739977L60.3442 48.38H5.33425Z" stroke="#010102" stroke-width="0.739979" />
                            </svg>
                            <h2>{t('Feature-t4')}</h2>
                            <p>{t('Feature-p2')}</p>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="65" viewBox="0 0 66 65" fill="none">
                                <path d="M16.9406 0.000366211V65" stroke="#010102" stroke-width="0.739979" />
                                <path d="M49.8856 0V64.9996" stroke="#010102" stroke-width="0.739979" />
                                <path d="M65.913 48.973L0.913339 48.973" stroke="#010102" stroke-width="0.739979" />
                                <path d="M65.913 16.028L0.913339 16.028" stroke="#010102" stroke-width="0.739979" />
                            </svg>
                            <h2>{t('Feature-t5')}</h2>
                            <p>{t('Feature-p3')}</p>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Features