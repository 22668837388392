import React, { useEffect } from 'react';
import './footer.scss';
import { useNavigate, useLocation } from 'react-router-dom'; // Using useNavigate for routing and useLocation for getting the URL details
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();


  return (
    <>

      <section className="main-footer">
        <div className='custom-container'>
          <div className="parent">
            <div className="left-side">
              <div className="inside-content">
                <a href='/'>
                  <img src="/logo-new.svg" alt="img" className="img-fluid logo-img" />
                </a>


                <div className="social-icons">
                  <a href=' https://www.linkedin.com/company/olympus_ai
' target='/'>
                    <img
                      className="hover-img"
                      src='\assets\link.svg'
                      alt="Image"
                    />
                  </a>
                  <a href="https://x.com/Olympus_AI_" target="_blank">
                    <img
                      className="hover-img"
                      src='/assets/xpic.svg'
                      alt="Image"
                    />
                  </a>


                  <a href="https://t.me/Olympus_ai" target="_blank" rel="noopener noreferrer">
                    <svg className="sendd" xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                      <rect y="0.338379" width="28" height="28" rx="14" fill="#F5F6F6" />
                      <path d="M6.29263 14.0296C10.3639 12.2185 13.0787 11.0246 14.4371 10.4477C18.3155 8.80064 19.1214 8.51452 19.6466 8.50497C19.7622 8.503 20.0205 8.53223 20.1878 8.67086C20.3291 8.78791 20.368 8.94603 20.3866 9.05701C20.4052 9.16799 20.4283 9.42081 20.4099 9.61835C20.1998 11.8731 19.2903 17.3446 18.8277 19.87C18.6319 20.9385 18.2464 21.2968 17.8733 21.3319C17.0623 21.4081 16.4464 20.7846 15.6609 20.2589C14.4317 19.4362 13.7374 18.9241 12.5442 18.1213C11.1654 17.1936 12.0592 16.6837 12.845 15.8504C13.0507 15.6323 16.624 12.3138 16.6932 12.0127C16.7018 11.9751 16.7099 11.8347 16.6282 11.7606C16.5465 11.6865 16.426 11.7119 16.339 11.732C16.2158 11.7606 14.2522 13.0857 10.4483 15.7075C9.89091 16.0982 9.38607 16.2886 8.93375 16.2786C8.43511 16.2676 7.47592 15.9908 6.76286 15.7541C5.88826 15.4638 5.19315 15.3104 5.25367 14.8174C5.2852 14.5606 5.63152 14.298 6.29263 14.0296Z" fill="#020F20" />
                    </svg>
                  </a>


                </div>
                <p>{t('Footer-t1')}</p>
                <div className="twice-links">
                  <Link to="/terms">{t('Footer-t2')}</Link>
                  <span></span>
                  <Link to="/privacy">{t('Footer-t3')}</Link>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="parent-inside">

              </div>
              {/* <div className="parent-inside">
              <div className="single-list">


                Links to scroll to specific sections
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section1');
                  }}
                  href="#section1"
                >
                  Contact Us
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section2');
                  }}
                  href="#section2"
                >
                  Career
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section3');
                  }}
                  href="#section3"
                >
                  Blog
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section4');
                  }}
                  href="#section4"
                >
                  About Us
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleScrollClick('section4');
                  }}
                  href="#section4"
                >
                  Privacy Policy
                </a>

              </div>
            </div> */}
            </div>
          </div>

          <p className="d-none right-reserved-text">©{currentYear} {t('Footer-t1')}</p>
          <div className="twice-links d-none right-reserved-text">
            <Link to="/terms">{t('Footer-t2')}</Link>
            <span></span>
            <Link to="/privacy">{t('Footer-t3')}</Link>
          </div>
        </div>
      </section>

    </>
  );
};

export default Footer;
